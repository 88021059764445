<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Khuyến mại</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <!-- Start: Status -->
      <div class="">
        <div class="font-weight-bold mb-2">Loại khuyến mại</div>
        <v-radio-group v-model="selectedType" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="all"></v-radio>

          <v-radio label="Theo sản phẩm" :value="1"></v-radio>

          <div v-if="selectedType === 1" class="mb-3 ml-5">
            <div v-for="item in PROMOTION_TYPE_ARRAY" :key="item.value">
              <v-checkbox
                v-model="selectedPromotionTypes"
                class="mt-0"
                hide-details
                :label="item.label"
                :value="item.value"
              ></v-checkbox>
            </div>
          </div>
          <v-radio label="Theo hóa đơn" :value="2"></v-radio>
        </v-radio-group>
      </div>
      <!-- End: Status -->
      <!-- Start: Status -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-1"
          dense
          hide-details
          label="Lên kế hoạch"
          :value="2"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-0"
          dense
          hide-details
          label="Đang chạy"
          :value="1"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-0"
          dense
          hide-details
          label="Hết hạn"
          :value="3"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-0"
          dense
          hide-details
          label="Hết lượt sử dụng"
          :value="4"
        ></v-checkbox>
      </div>
      <!-- End: Status -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nhân viên tạo</div>

        <v-checkbox
          v-model="selectedcreatedByMyself"
          class="mt-0 mb-2"
          hide-details
          label="Được tạo bởi tôi"
        ></v-checkbox>

        <tp-autocomplete
          v-model="selectedEmployees"
          :items="employees"
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          small-chips
          multiple
          placeholder="Chọn nhân viên"
        ></tp-autocomplete>
      </div>
      <!-- Start: Start Time -->
      <div class="mt-5 mb-2">
        <div class="font-weight-bold mb-2">Thời gian bắt đầu</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>
      <!-- End: Start Time -->
    </v-card>
  </div>
</template>

<script>
import { PROMOTION_TYPE_ARRAY } from "@/modules/Promotion/constants";
export default {
  props: {
    createdByMyselfFilter: {
      type: Boolean
    },
    dateFilter: {
      type: Object
    },
    employeesFilter: {
      type: Array
    },
    promotionTypesFilter: {
      type: Array,
      default: () => []
    },
    statusFilter: {
      type: Array
    },
    typeFilter: {
      type: [Number, String]
    }
  },

  data() {
    return {
      PROMOTION_TYPE_ARRAY: PROMOTION_TYPE_ARRAY
    };
  },

  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    selectedcreatedByMyself: {
      get() {
        return this.createdByMyselfFilter;
      },
      set(val) {
        this.$emit("updateCreatedByMyselfFilter", val);
      }
    },

    selectedEmployees: {
      get() {
        return this.employeesFilter;
      },
      set(val) {
        this.$emit("updateEmployeesFilter", val);
      }
    },
    selectedPromotionTypes: {
      get() {
        return this.promotionTypesFilter;
      },
      set(val) {
        this.$emit("updatePromotionTypesFilter", val);
      }
    },
    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    },
    selectedType: {
      get() {
        return this.typeFilter;
      },
      set(val) {
        this.$emit("updateTypeFilter", val);
      }
    }
  },

  async created() {
    if (this.employees.length === 0) {
      await this.$store.dispatch("EMPLOYEE/getAllEmployees");
    }
  },

  methods: {
    updateDateFilter(val) {
      this.$emit("updateDateFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
